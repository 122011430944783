import { graphql, Link } from "gatsby"
import React from "react"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles.css"
import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  const postsEachYear = {}
  posts.forEach(post => {
    const year = post.node.frontmatter.date.split(", ")[1]
    if (postsEachYear[year]) {
      postsEachYear[year].push(post)
    } else {
      postsEachYear[year] = [post]
    }
  })

  return (
    <Layout title={siteTitle}>
      <SEO location={location} title="Hossam Mourad" />
      <Bio />
      {Object.keys(postsEachYear)
        .sort((a, b) => a - b)
        .reverse()
        .map(year => {
          return (
            <div key={year}>
              <h4 style={{ fontWeight: "bold", letterSpacing: "1px" }}>
                {year}
              </h4>
              {postsEachYear[year].map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                return (
                  <article
                    key={node.fields.slug}
                    style={{
                      paddingBottom: `${rhythm(1)}`,
                      marginBottom: `${rhythm(1)}`,
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <header>
                      <h3
                        style={{
                          margin: "0",
                          fontWeight: "bold",
                        }}
                      >
                        <Link
                          style={{ boxShadow: `none` }}
                          to={node.fields.slug}
                        >
                          {title}
                        </Link>
                      </h3>
                      <small style={{ color: "gray" }}>
                        {node.frontmatter.date}
                      </small>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{ __html: node.excerpt }}
                        style={{
                          marginBottom: 0,
                        }}
                      />
                    </section>
                  </article>
                )
              })}
            </div>
          )
        })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
