/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { rhythm } from "../utils/typography"

const Bio = ({ includeName }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            email
            linkedin
            github
            twitter
            resume
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  const { resume, email, linkedin, github, twitter } = social

  const renderName = () => {
    if (!includeName) return null
    return (
      <>
        Written by <strong>{author.name}</strong>.
        <br />
      </>
    )
  }

  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(1.5),
      }}
    >
      <p>
        {renderName()}
        {author.summary}
        <br />
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>{" "}
        /{" "}
        <a href={resume} target="_blank" rel="noopener noreferrer">
          Resume
        </a>{" "}
        / <a href={email}>Email</a> /{" "}
        <a href={twitter} target="_blank" rel="noopener noreferrer">
          Twitter
        </a>{" "}
        /{" "}
        <a href={github} target="_blank" rel="noopener noreferrer">
          GitHub
        </a>
      </p>
    </div>
  )
}

Bio.defaultProps = {
  includeName: false,
}

export default Bio
